<template>
  <div>

    <!-- Alert: -->
    <b-alert
      variant="danger"
    >
      <h4 class="alert-heading">
      </h4>
      <div class="alert-body">
      </div>
    </b-alert>
    <div>
      <validation-observer 
        ref="importForm"
        #default="{ invalid }"
        >
        <b-form
          @submit.prevent="importCodes"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                md="4"
                lg="4"
              >
                <b-form-group
                    label-for="file"
                  >
                  <template v-slot:label>
                    {{ $t('arl_codes.upload.field.file') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('arl_codes.upload.field.file')"
                    rules="required"
                    >
                    <b-form-file
                      id="file"
                      v-model="fileData.file"
                      accept=".xls, .xlsx"
                      :placeholder="$t('arl_codes.upload.label.selectFile')"
                      drop-placeholder="Suéltelo aquí..."
                      browse-text="Buscar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3"
                lg="3"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="invalid"
                >
                  {{ $t('arl_codes.upload.submit')}}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="9"
                md="9"
                lg="9"
              >
                <b-card-text>
                  {{ $t('arl_codes.upload.label.selected') }}: <strong>{{ fileData.file ? fileData.file.name : 'Ninguno' }}</strong>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
    <div v-if="details.length != 0">
      <b-card>
        <b-row class="flex-row flex-nowrap">
          <b-col
            cols="12"
          >
          <vue-double-scrollbar>
            <vue-double-scrollbar>
              <b-table
                :items="details"
                :fields="detailsColumns"
                class="text-center"
                striped
                thead-class="table-primary text-primary"
              >

                <template #cell(freezerStatus)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariantError(data.item.freezerStatus)}`"
                  >
                    {{ $t(`arl_codes.upload.status.${data.item.freezerStatus}`) }}
                  </b-badge>
                </template>

              </b-table>
            </vue-double-scrollbar>
    </vue-double-scrollbar>
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <b-button  
                variant="primary"
                @click="export2excel"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              >
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import elementStoreModule from './storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BFormFile,
  BCard,
  BCardText,
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormDatepicker,
  BButton,
  BFormSelect,
  BForm,
  BFormGroup,
  BBadge
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import * as XLSX from 'xlsx'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormFile,
    BCard,
    BCardText,
    BFormDatepicker,
    BButton,
    BFormSelect,
    BFormGroup,
    BForm,
    BTable,
    BBadge,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      fileData: {
        arlId: null,
        date: new Date(),
        file: null
      },
      file: null,
      uploadSuccess: false,
      companyList: [],  
      details:[],
      detailsColumnsError: [
        { key: 'type', label: this.$t("arl_codes.upload.label.type"), sortable: true },
        { key: 'message', label: this.$t("arl_codes.upload.label.message"), sortable: true }
      ],
      detailsColumnsSuccess: [
        { key: 'medical_order_id', label: this.$t("freezer.upload.label.medical_order_id"), sortable: true },
        { key: 'case_number', label: this.$t("freezer.upload.label.case_number"), sortable: true },
        { key: 'identity', label: this.$t("freezer.upload.label.identity") },
        { key: 'name', label: this.$t("freezer.upload.label.name"), sortable: true },
        { key: 'guide_number', label: this.$t("freezer.upload.label.guide_number"), sortable: true },
        { key: 'observations', label: this.$t("freezer.upload.label.observations"), sortable: true },
        { key: 'weight', label: this.$t("freezer.upload.label.weight"), sortable: true },
        { key: 'commercial_value', label: this.$t("freezer.upload.label.commercial_value"), sortable: true },
        { key: 'freezerStatus', label: this.$t("freezer.upload.label.freezerStatus"), sortable: true },
        { key: 'freezerErrorMessage', label: this.$t("freezer.upload.label.freezerErrorMessage"), sortable: true }
      ]
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'upload'
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-arl-codes-' + this.RESOURCES_ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    this.details = []

  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    resolveStatusVariant(status) {
      if (status === 'error') return 'danger'
      return 'warning'
    },
    resolveStatusVariantError(status) {
      if (status === 'exist' || status === 'error') return 'danger'
      return 'success'
    },
    importCodes() {
      let _self = this
      let action = 'upload'
      this.details = []
      this.uploadSuccess = false
      this.$refs.importForm.validate().then(success => {
        if (success) { 
          store
            .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/importCodes', this.fileData)
            .then(response => {

              _self.detailsColumns = _self.detailsColumnsSuccess
              _self.uploadSuccess = true
              _self.details = response.data.details

              console.info('details:', response.data.details)

              this.fileData.date = new Date()
              this.fileData.file = null
              this.$refs.importForm.reset();

              _self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: _self.$t(`arl_codes.${action}.action_results.title`),
                  icon: 'SaveIcon',
                  variant: 'success',
                  text: _self.$t(`arl_codes.${action}.action_results.success`),
                },
              })
            })
            .catch((error) => {
              console.info(error)
              if (error.response.data) {
                _self.detailsColumns = _self.detailsColumnsError
                _self.details = error.response.data.details
              }
              _self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
                  icon: 'SaveIcon',
                  variant: 'danger',
                  text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`),
                },
              })
            })
        }
      })
    },
    filterJson(jsonData) {
      let filterList = this.detailsColumnsSuccess.map(function(a) {return a.key});
      //filterList.pop();
      return jsonData.map(v => filterList.map(j => {
        return v[j]
      }))
    },
    getHeader() {
      const res = {};
      for (let i=0; i < this.detailsColumnsSuccess.length; i++) {
        res[this.detailsColumnsSuccess[i].key] = this.detailsColumnsSuccess[i].label;
      }
      return res;
    },
    format(jsonData) {
      for (let i=0; i < jsonData.length; i++) {
        jsonData[i].patientStatus = jsonData[i].patientStatus ? this.$t(`case.upload.status.${jsonData[i].patientStatus}`) : ""
        jsonData[i].companyStatus = jsonData[i].companyStatus ? this.$t(`case.upload.status.${jsonData[i].companyStatus}`) : ""
        jsonData[i].caseStatus = jsonData[i].caseStatus ? this.$t(`case.upload.status.${jsonData[i].caseStatus}`) : ""
      }
    },
    async export2excel() {
      const filename = 'resultados'
      let list = JSON.parse(JSON.stringify(this.details))
      this.format(list)
      list.unshift(this.getHeader())
      list = this.filterJson(list)
      let data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
  }
}
</script>

<style>
  .scrollbar-container {
    overflow-y: auto;
    margin: 0 0;
  }

  .scrollbar-content {
    margin: 0 0;
  }

  .scrollbar-flipped, .scrollbar-flipped .scrollbar-content {
    transform: rotateX(180deg);
  }
</style>
